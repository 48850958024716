!((w, d) => {
  const dtm = {
    addSatellite: () => {
      if (d.body && w['_satellite']) {
        const satelliteScript = d.createElement('script');
        satelliteScript.innerHTML = '_satellite.pageBottom()';
        d.body.appendChild(satelliteScript);
      } else {
        setTimeout(() => dtm.addSatellite(), 500);
      }
    }
  };

 w.onload = () => { dtm.addSatellite(); };

})(window, document);
